import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const BannerParagraph = ({ node }) => (
  <div className="paragraph paragraph-banner">
      <GatsbyImage
          image={node.relationships.banner.localFile.childImageSharp.gatsbyImageData}
          alt={node.banner.alt} />
  </div>
)

export const fragment = graphql`
  fragment BannerParagraph on paragraph__banner {
    id
    banner: field_image {
      alt
    }
    relationships {
      banner: field_image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              transformOptions: {cropFocus: CENTER}
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  }
`;
