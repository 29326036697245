import React, {useContext, useEffect, useState} from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {useSwipeable} from "react-swipeable";
import {ModalContext} from "../../contexts/ModalContext";

export const ModalImagesSlideshow = ({active, images, imagesData}) => {
    const first = 0;
    const last = images.length - 1;
    const [activeIndex, setActiveIndex] = useState(active)
    const {modalKeyDownEvent} = useContext(ModalContext)

    const next = () => {
        setActiveIndex(activeIndex === last ? first : activeIndex + 1)
    }

    const previous = () => {
        setActiveIndex(activeIndex === first ? last : activeIndex - 1)
    }

    const swipeHandler = useSwipeable({
        onSwipedLeft: eventData => next(),
        onSwipedRight: eventData => previous()
    })

    useEffect(() => {
        if (null !== modalKeyDownEvent && typeof modalKeyDownEvent !== 'undefined') {
            switch (modalKeyDownEvent.key) {
                case "ArrowLeft":
                    previous()
                    break;
                case "ArrowRight":
                    next()
                    break;
                default:
                    break;
            }
        }
    }, [modalKeyDownEvent])

    return (
        <div className="modal-images-slideshow is-relative">
            <div className="images relative" {...swipeHandler}>
                {images.map((image, index) => {
                    let data = imagesData[index]

                    return (
                        <figure key={index} className={`image ${index === activeIndex ? `is-block` : `is-hidden`}`}>
                            <GatsbyImage
                                image={image.localFile.childImageSharp.gatsbyImageData}
                                alt={data.alt}
                                title={data.title}
                                style={{maxHeight: "80vh"}} />
                            {data.title && (
                                <figcaption className="image-caption">{data.title}</figcaption>
                            )}
                        </figure>
                    );
                })}
            </div>

            {last > first && (
                <nav>
                    <button
                        className="previous"
                        onClick={previous}
                    >
                        <i className="fas fa-chevron-left"><span className="is-hidden">Previous</span></i>
                    </button>

                    <button
                        className="next"
                        onClick={next}
                    >
                        <i className="fas fa-chevron-right"><span className="is-hidden">Next</span></i>
                    </button>
                </nav>
            )}
        </div>
    );
}