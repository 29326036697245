import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import MenuLink from "../MenuLink";
import HtmlParser from "../HtmlParser";

export const CardParagraph = ({ node }) => {
  return (
    <div className={`paragraph paragraph-card card${node.link ? ` has-icon-link` : ``}`}>
      {node.relationships.image && (
        <div className="card-image">
            <GatsbyImage
                image={node.relationships.image.localFile.childImageSharp.gatsbyImageData}
                alt={node.title} />
        </div>
      )}
      <div className="card-content" data-typesense-field="paragraph_card">
        <div className="title is-3">{node.title}</div>
        <div className="content">
          <HtmlParser html={node.text.value}/>
        </div>
        {node.link && (
          <div className="link">
            <MenuLink
              internalId={node.link.uri}
              className="button is-icon-only is-primary is-rounded"
              title={node.link.title}
            >
              <span className="icon icon-medium">
                <i className="li li-plus"></i>
                <span className="icon-explain is-sr-only">{node.link.title}</span>
              </span>
            </MenuLink>
          </div>
        )}
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment CardParagraph on paragraph__card {
    id
    image: field_image {
      alt
    }
    title: field_title
    text: field_text {
      value
    }
    link: field_link {
      title
      uri
    }
    relationships {
      image: field_image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 555
              height: 312
              quality: 100
              transformOptions: {cropFocus: CENTER}
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`;
