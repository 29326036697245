import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import ArticleListItem from "../ListItems/ArticleListItem";

const OtherArticles = ({current, limit}) => {
    const data = useStaticQuery(graphql`{
        otherArticles: allNodeArticle(
            limit: 4,
            filter: {
                status: {eq: true},
            },
            sort: {fields: created, order: DESC}
        ) {
            nodes {
                nid: drupal_internal__nid
                title
                body {
                    summary
                    value
                }
                path {
                    alias
                }
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 480
                                    height: 270
                                    quality: 100
                                    transformOptions: {cropFocus: CENTER}
                                    layout: CONSTRAINED
                                )
                            }
                        }
                    }
                    tags: field_tags {
                        name
                        path {
                            alias
                        }
                    }
                }
            }
        }
    }`)

  let otherArticles = data.otherArticles.nodes.filter(({nid}) => {
    return nid !== current;
  })

  otherArticles = otherArticles.slice(0, limit);
  
  return otherArticles.length > 0 ? (
    <section className="section page-content-suggestion has-background-halflight">
        <div className="container">
            <h2 className="title is-2 has-text-centered">Voir d'autres actualités</h2>
            <div className="columns">
                {otherArticles.map((node, index) => (
                    <div key={index} className="column is-6 is-4-desktop">
                        <ArticleListItem content={node}/>
                    </div>
                ))}
            </div>
        </div>
    </section>
  ) : (
    <></>
  )
}

export default OtherArticles