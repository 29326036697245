import React from "react";
import { graphql } from "gatsby";
import { getIconFromExtension } from "../../Utils/functions";

export const FilesParagraph = ({ node }) => {
    return (
        <div className={`paragraph paragraph-files`}>
            <div className="content">
                {node.title && (
                    <h2 className={"title is-2"}>{node.title}</h2>
                )}
                <div className="columns is-multiline">
                    {node.files.map((file, index) => {
                        let fileInfos = node.relationships.files[index]

                        return (
                            <div key={index} className="column is-4">
                                <a
                                    href={fileInfos.localFile.publicURL}
                                    title={`${file.description} (${fileInfos.localFile.prettySize})`}
                                    className="file"
                                    download
                                >
                                    <span className="icon icon-large">
                                        <i className={`fas fa-${getIconFromExtension(fileInfos.localFile.extension)}`}></i>
                                    </span>
                                    <span className="icon-explain">{file.description}</span>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export const fragment = graphql`
    fragment FilesParagraph on paragraph__files {
        id
        title: field_title
        files: field_files {
            description
        }
        relationships {
            files: field_files {
                localFile {
                    publicURL
                    prettySize
                    extension
                }
            }
        }
    }
`;
