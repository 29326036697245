import React, {useContext} from "react";
import {graphql} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {ModalContext} from "../../contexts/ModalContext";
import {ModalImagesSlideshow} from "../Modal/ModalImagesSlideshow";

export const ImageGalleryParagraph = ({node}) => {
    const {setModalVisibility, setModalContent} = useContext(ModalContext)

    const handleImageClick = (index) => {
        setModalContent(
            <ModalImagesSlideshow active={index} images={node.relationships.imagesLarge} imagesData={node.images}/>
        )

        setModalVisibility(true)
    }

    return (
        <div className="paragraph paragraph-image-gallery">
            <div className="content">
                {node.title && (<h2 className="title is-2">{node.title}</h2>)}
                <div className="columns is-multiline">
                    {node.relationships.images.map((image, index) => {
                        let imageData = node.images[index]

                        return (
                            <div key={index} className="column is-3">
                                <figure className="image is-relative" onClick={() => handleImageClick(index)}>
                                    <GatsbyImage
                                        image={image.localFile.childImageSharp.gatsbyImageData}
                                        alt={imageData.alt}
                                        title={imageData.title}
                                    />
                                    <figcaption className="image-caption">
                                        {imageData.title ? imageData.title : (
                                            <i className="fas fa-search-plus"><span className="is-hidden">Agrandir</span></i>
                                        )}
                                    </figcaption>
                                </figure>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}


export const fragment = graphql`fragment ImageGalleryParagraph on paragraph__image_gallery {
  id
  title: field_title
  images: field_images {
    alt
    title
  }
  relationships {
    imagesLarge: field_images {
      localFile {
        childImageSharp {
          gatsbyImageData(height: 625, quality: 100, layout: CONSTRAINED)
        }
      }
    }
    images: field_images {
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 352
            height: 352
            quality: 100
            transformOptions: {cropFocus: CENTER}
            layout: CONSTRAINED
          )
        }
      }
    }
  }
}
`;
