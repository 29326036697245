import React from "react";
import { graphql } from "gatsby";
import HtmlParser from "../HtmlParser";

export const HighlightedParagraph = ({ node }) => (
  <div className="paragraph paragraph-highlighted">
      <div className="content" data-typesense-field="paragraph_highlighted">
          <HtmlParser html={node.text.value}/>
      </div>
  </div>
)

export const fragment = graphql`
  fragment HighlightedParagraph on paragraph__highlighted {
    id
    text: field_text {
      value
    }
  }
`;
