import React from "react";
import { graphql } from "gatsby";
import { getParagraph } from '../../helpers/paragraph-helpers';

export const ListParagraph = ({ node }) => {
  const columnSize = 12 / node.itemsPerLine;

  return (
    <div className="paragraph paragraph-list">
      <div className="columns is-multiline">
        {node.relationships.listItems.map((listItem, index) => (
          <div className={`column is-${columnSize}`} key={index}>
            {getParagraph(listItem)}
          </div>
        ))}
      </div>
    </div>
  )
};

export const fragment = graphql`
  fragment ListParagraph on paragraph__list {
    id
    itemsPerLine: field_items_per_line
    relationships {
      listItems: field_paragraphs {
        type: __typename
        ... on Node {
          ...TextParagraph
          ...HighlightedParagraph
          ...CardParagraph
          ...ImageParagraph
        }
      }
    }
  }
`;
