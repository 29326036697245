import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import {getParagraph} from '../helpers/paragraph-helpers';
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Title from "../components/Title";
import HtmlParser from "../components/HtmlParser";
import OtherArticles from "../components/Blocks/OtherArticles";

const Article = ({pageContext, data}) => {
  const {
    breadcrumb: {crumbs},
  } = pageContext

  let {alt, width, height} = data.node.image
  let [maxWidth, maxHeight] = [576, 576]
  let calcHeight = height * maxWidth / width

  if (calcHeight > maxHeight) {
    maxWidth = maxHeight * maxWidth / calcHeight
  }

  let paragraphs = [];
  if (null !== data.node.relationships.paragraphs) {
      paragraphs = data.node.relationships.paragraphs.map(getParagraph);
  }

  return (
    <Layout contentType={data.node.internal.type}>
      <Metas title={data.node.title}/>
      <section className="section page-breadcrumb is-small has-background-light">
        <div className="container">
          <CustomBreadcrumb crumbs={crumbs}/>
        </div>
      </section>
      <section className="section page-content">
        <div className="container">
          <Title title={data.node.title} className="has-text-centered"/>

          {data.node.relationships.image && (
            <div className="block figure has-text-centered">
                <figure className="image" style={{margin: '0 auto',maxWidth: `${maxWidth}px`}}>
                    <GatsbyImage
                        image={data.node.relationships.image.localFile.childImageSharp.gatsbyImageData}
                        alt={alt ? alt : data.node.title} />
                </figure>
            </div>
          )}

          {data.node.body && (
              <div className="block content" data-typesense-field="body">
                <HtmlParser html={data.node.body.value}/>
              </div>
          )}

          {paragraphs.length > 0 && (
              <div className="paragraphs-list">
                  {paragraphs}
              </div>
          )}

          <div className="date tags is-centered">
            <span className="tag" data-typesense-field="changed">Dernière maj : {data.node.changed}</span>
          </div>
        </div>
      </section>

      <OtherArticles current={pageContext.internalId} limit={3}/>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($internalId: Int!) {
      node: nodeArticle(
          status: {eq: true},
          drupal_internal__nid: {eq: $internalId}
      ) {
          title
          body {
              value
          }
          image: field_image {
            alt
            width
            height
          }
          changed
          internal {
              type
          }
          relationships {
              image: field_image {
                  localFile {
                      childImageSharp {
                          gatsbyImageData(width: 576, layout: CONSTRAINED)
                      }
                  }
              }
              paragraphs: field_paragraphes {
                  type: __typename
                      ... on Node {
                      ...TextParagraph
                      ...BannerParagraph
                      ...HighlightedParagraph
                      ...ListParagraph
                      ...FilesParagraph
                      ...ImageGalleryParagraph
                  }
              }
          }
      }
  }
`
